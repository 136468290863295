<template>
  <TheLayout>
    <div
      class="h-[calc(100vh-40px)] md:h-[calc(100vh-80px)] xl:h-[calc(100vh-100px)]"
    >
      <slot />
    </div>
  </TheLayout>
</template>

<script>
import TheLayout from '~/components/TheLayout.vue'
import LightboxMixin from '~/mixins/LightboxMixin'

export default {
  components: {
    TheLayout,
  },
  mixins: [LightboxMixin],
  mounted() {
    this.$store.commit('menu/setActiveMenu', {
      activeMenu: null,
      activeSubMenu: null,
    })
  },
}
</script>
